import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { FsessionService } from 'src/app/servicios/session/fsession.service';

export enum ValidRoles {
  ADMINISTRADOR = 1,
  ESTANDAR = 2,
  DEMO = 3,
  SUPERVISOR = 4,
  ROL_DE_PRUEBAS = 5,
  CLIMA = 6,
  ADMINISTRADOR_MUNICIPIO = 7,
  MULTIMUNICIPIO = 8,
  SUPER_ADMINISTRADOR = 9
}

@Directive({
  selector: '[appValidateRoles]'
})
export class ValidateRolesDirective implements OnChanges {

  /** arreglo de ids (numeros) que representan un rol de usuario */
  @Input() idRoles: ValidRoles[] = [];

  /** Arreglo de IDs (números) que representan roles excluidos */
  @Input() excludedRoles: ValidRoles[] = [];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _fsessionService: FsessionService
  ) { }

  ngOnChanges() {
    const idRolUser = +this._fsessionService.optenerValor('dato40');

    /** Cuando se le pasen los id de roles a comprobar */
    if ( this.idRoles.length > 0 ) {
      this.checkAllowedRoles(idRolUser);
    }

    if (this.excludedRoles.length > 0) {
      this.checkExcludedRoles(idRolUser);
    }
  }

  /**
   * Comprueba si el rol del usuario está permitido.
   * Si no está permitido, elimina el elemento del DOM.
   * @param idRolUser - ID del rol del usuario logueado.
   */
  private checkAllowedRoles(idRolUser: number) {
    if (!this.idRoles.includes(idRolUser)) {
      this.removeElement();
    }
  }

  /**
   * Comprueba si el rol del usuario está excluido.
   * Si está excluido, elimina el elemento del DOM.
   * @param idRolUser - ID del rol del usuario logueado.
   */
  private checkExcludedRoles(idRolUser: number) {
    if (this.excludedRoles.includes(idRolUser)) {
      this.removeElement();
    }
  }

  /**
   * Elimina el elemento del DOM.
   */
  private removeElement() {
    this.renderer.removeChild(this.el.nativeElement.parentNode, this.el.nativeElement);
  }

} 
