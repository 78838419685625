import { Component, ViewChild } from "@angular/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import packageJson from '../../../../package.json';
import { DatabaseService } from "src/app/servicios/database/database.service";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { UpdateService } from "src/app/servicios/update/update.service";
import { FsessionService } from "src/app/servicios/session/fsession.service";
import { MatCheckboxChange } from "@angular/material/checkbox";


@Component({
  selector: "app-acerca-de",
  templateUrl: "./acerca-de.component.html",
  styleUrls: ["./acerca-de.component.scss"],
  providers: [
    CdkCopyToClipboard
  ]
})
export class AcercaDeComponent {
  @ViewChild("aboutSwal") aboutSwal: SwalComponent;
  @ViewChild("changesSwal") changesSwal: SwalComponent;
  @ViewChild("terminos") terminos: SwalComponent;

  fileUrl =
    "https://www.ccn-cert.cni.es/amparo/API/public/certificaciones/3086/download/2077";

  swalConfirmButtonAndIconColor = '#294ADB';
  swalWidth = '450px';
  
  version: string = packageJson.version;
  versionInfo = null

  dontShowAgain: boolean = false;
  ultimo_inicio_sesion: string;

  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private db: DatabaseService,
    private clipboard: CdkCopyToClipboard,
    private updateService: UpdateService,
    private fse: FsessionService,
  ) {
    this.db.getTerminoEnsUsuario().subscribe((res)=>{
      this.ultimo_inicio_sesion = res[0].ultimo_inicio_sesion;
    })
  }

  getCurrentVersionChanges() {
    this.getVersionChanges(this.version);
  }

  getVersionChanges(version) {
    this.db.getLatestRelease(version).subscribe(
      (release) => {
        this.versionInfo = release;
        this.changesSwal.fire();
      }
    )
  }

  clearUpdateMessage() {
    this.updateService.updateMessageShown();
  }

  copyReleaseNotes() {
    this.clipboard.text = this.versionInfo.body;
    this.clipboard.copy()
    return false;
  }

  checkUpdates() {
    this.updateService.checkForUpdate();
  }

  /**
   * Confirma los terminos del servicio por parte del usuario
   */
  confirmarTerminos() {
    this.fse.agregarActualizar('terminos', 'true');
    if(this.dontShowAgain) {
      this.db.updateTerminoEnsUsuario(this.dontShowAgain).subscribe(() => {
        location.replace('/traffic');
      })
    }
  }

  /**
   * No volver a mostrar el mensaje de advertencia de terminos ENS
   * @param event event
   */
  onCheckboxChange(event: MatCheckboxChange) {
    this.dontShowAgain = event.checked;
  }
}
