import { ValidatorFn } from "@angular/forms";
import { ValidRoles } from "../utils/directives/validateRoles/validate-roles.directive";

export interface ColumnSchemaTable {
    title: string;
    name: string
}

export interface ActionsSchemaTable {
    title: string;
    name: string;
    buttonColor: buttonColor;
    icon: string;
}

export interface HandleActionSchemaTable {
    name: string;
    pressed: boolean;
    data: any;
}

export interface LeftRightButtonSchemaTable {
    name: string;
    title: string;
    type: buttonType;
    disabled: boolean;
    color?: buttonColor;
    icon?: string;
    iconMatTooltip?: string;
    validateRoles?: ValidRoles[];
    checked?: boolean;
}

export interface CenterInputSchemaTable {
    name: string;
    labels: string[];
    type: InputType;
    appearance: formFieldType;
    validators?: ValidatorFn[];
    iconCleanInput?: boolean;
    placeholder?: string;
    value?: string;
}

declare type buttonColor = 'primary' | 'accent' | 'warn' | 'basic';
export declare type buttonType = 'basic' | 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab' | 'slide-toggle';
declare type InputType = 'text' | 'number' | 'email';
declare type formFieldType = 'legacy' | 'standard' | 'fill' | 'outline';