export interface Tracking {
  idrouter: number;
  Latitud: string;
  Longitud: string;
  fecha: string;
  Altitud: number;
  timestamp_gps: number; 
  timestamp_router: number;
  nsatelites: number; 
  estado: number
  routerName?: string;
}

export interface CurrenTracking extends Tracking {
  tag: string;
  index: number;
  selected: boolean
}

export type InterestPointActions = 'update' | 'delete';

export interface RefreshTimeUpdate {
  miliseconds: MilisecondsRefreshTimeUpdate;
  name: NameRefreshTimeUpdate
}

export interface InterestPoint {
  idPuntoInteres: number;
  nombre: string;
  descripcion: string;
  latitud: string;
  longitud: string;
  categoria: number;
  idMunicipio: number;
  nombreCategoria?: string;
  descripcionCategoria?: string;
  iconoCategoria?: string;
}

export enum NameRefreshTimeUpdate {
  '10s' = '10s',
  '30s' = '30s',
  '1m' = '1m',
  '5m' = '15m',
  'refresco_manual' = 'refresco_manual'
}

export enum MilisecondsRefreshTimeUpdate {
  '10s' = 10000,
  '30s' = 30000,
  '1m' = 60000,
  '5m' = 300000,
  'refresco_manual' = 0
}

export interface TrackingCategory {
  idCategoria: number;
  idMunicipio: number;
  nombre: string;
  descripcion: string;
  icono: string
}